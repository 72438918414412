import './App.css';
import NavBar from "./components/NavBar.js";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import ContentPanel from "./components/ContentPanel";
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import Home from "./components/Home";
import Services from "./components/Services";
import Framing from "./components/Framing"
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import * as React from "react";
import Footer from "./components/Footer";
import LocalArt from "./components/LocalArt";
// import OrderForm from "./components/OrderForm";

let theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#5B534F',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#5B534F',
    },
    text: {
      primary: '#212121',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeight: 100,
    color: 'black',
    h1: {
      fontWeight: 100,
      fontSize: '4.8rem',
      marginTop: 65,
    },
    h2: {
      fontWeight: 100,
      fontSize: '3.6rem',
      marginTop: 60,
      marginBottom: 20,
    },
    h3: {
      fontSize: '2.1rem',
      fontWeight: 300,
      marginTop: 50,
      marginBottom: 12,
    },
    body1: {
      fontSize: '1.6rem',
      lineHeight: 1.1,
      fontWeight: 100,
      letterSpacing: '0.1em',
      marginTop: 15,
      marginBottom: 15
    },
    body2: {
      fontSize: '1.2rem',
      fontWeight: 300,
      letterSpacing: '0.1em',
    },
    caption: {
      fontSize: '1.2rem',
      fontWeight: 300,
      letterSpacing: '0.1em',
      marginTop: 0,
      paddingTop: 0
    },
    button: {
      letterSpacing: '0.23em',
      fontWeight: 500,
      fontSize: '1.4rem',
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div>
          <NavBar />

          <ContentPanel>
            <Routes>
              <Route path="/" element={<Home/>} exact/>
              <Route path="/interiors" element={<Services/>}/>
              <Route path="/framing" element={<Framing/>}/>
              <Route path="/localart/*" element={<LocalArt/>}/>
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/contact" element={<Contact/>} />
              {/*<Route path="/order" element={<OrderForm/>} />*/}
              <Route component={Home}/>
            </Routes>
          </ContentPanel>

        </div>

        <Footer />

      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
