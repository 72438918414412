import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";


const Home = (props) => {
  return (
    <Box>
      <Box>
          <img
            src={`${process.env.PUBLIC_URL}/images/eames_chair.png`}
            alt="Eames Lounge Chair with text overlay: Design for the way you live."
            loading="lazy"
            width="100%"
          />
      </Box>

      <Box mt={6} sx={{mx: {xs: 1, sm: 3, md: 5, lg:8}} }>
        <Typography variant="body1" align="center">
          Grain & Gather is a Seattle-based interior design firm specializing in contemporary interiors.
        </Typography>

        <Typography variant="h2" align="center">Hi, I’m Jean!</Typography>

        <Typography variant="body1" align="center">
          I’m the owner and principal designer at Grain & Gather. I entered the world of interiors via engineering and human-centered design. I especially love the functional aspects of design — I’ll always start by asking you how you’ll use your space and let your answers lead the way.
        </Typography>

        <Typography variant="h2" align="center"><del>Eat</del> Art Local</Typography>

        <Typography variant="body1" align="center">
          I'm proud to support local PNW artists by offering their framed works for sale on my site and recommending them to design clients when I see a good fit. <Link href="/localart" sx={{color: "#6e554e", fontWeight:400}}>Get yours here</Link>!
        </Typography>

        <Typography variant="h2" align="center">Let’s get started.</Typography>

        <Typography variant="body1" align="center">
          Please <Link href="mailto:design@grainandgather.com" sx={{color: "#6e554e", fontWeight:400}}>contact me</Link> to get started. Once I understand the scope and budget of your project, I’ll propose a pricing structure and plan for working together to design something amazing.
        </Typography>


      </Box>

    </Box>
  );
};

export default Home;