import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Contact = (props) => {
  return (
    <Box sx={{mx: {xs: 1, sm: 3, md: 5, lg:8}} }>
      <Typography variant="h2" sx={{mt: "40px"}} mt={4} gutterBottom>Contact</Typography>

      <Typography variant="body1">
        Contact me at <Link href="mailto:design@grainandgather.com" sx={{color: "#6e554e", fontWeight:400}}>design@grainandgather.com</Link> to set up a free design consultation!
      </Typography>
    </Box>
  );
};

export default Contact;