import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Link from "@mui/material/Link";

const pages = ['home', 'interiors', 'framing', 'local art', 'portfolio', 'contact'];

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky" variant="dense" style={{ background: '#FFFFFF' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>


          <Box component="div" sx={{mt: '15px', mb: '5px', mr: 2, display: { xs: 'none', md: 'flex' }}}>
            <Link href="/home">
              <img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt="Grain and Gather logo" height="50px" />
            </Link>
          </Box>


          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="main menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Button
                    href={"/" + (page === 'home'? "" : page.split(/\s+/).join('').toLowerCase())}
                    onClick={handleCloseNavMenu}
                    sx={{ mt: "10px", color: 'black', display: 'block', mx: '30px', p: '0px'}}
                    variant="link"
                    disableRipple>
                    {page}
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box component="div" sx={{mt: '15px', mb: '5px', flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Link href="/home">
              <img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt="logo" height="30px"/>
            </Link>
          </Box>

          <Box display="flex" sx={{ flexGrow: 1, justifyContent: "flex-end", alignItems:"flex-end", marginLeft:"auto", display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                href={"/" + (page === 'home'? "" : page.split(/\s+/).join('').toLowerCase())}
                onClick={handleCloseNavMenu}
                sx={{ mt: "10px", color: 'black', display: 'block', mx:2.5, p: '0px', minWidth:0}}
                variant="link"
                disableRipple
              >
                {page}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;