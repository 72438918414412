import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";
import Box from "@mui/material/Box";
import InstagramIcon from '@mui/icons-material/Instagram';


function Footer() {
  return (

      <Box mt="120px" pt="30px" pb="24px" sx={{backgroundColor: "#5B534F", zIndex: 0}} id="footer">
        <Box display="flex" justifyContent="center" alignItems="center">
         <img src={process.env.PUBLIC_URL + '/images/logo_light.svg'} height="40px" alt="logo"/>
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" mt="10px">
          <a id="github" href="https://www.instagram.com/grain_and_gather/">
            <InstagramIcon fontSize="large" sx={{"color": "white"}}/>
          </a>
        </Box>


        <Box display="flex" justifyContent="center" alignItems="center" mt="10px">
          <Typography variant="body1">
            <Link href="mailto:design@grainandgather.com" underline="none" color="white" sx={{ fontWeight:500}}>
              design@grainandgather.com
            </Link>
          </Typography>
        </Box>
      </Box>

  );
}

export default Footer;
