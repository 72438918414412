import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from "@mui/material/Grid";

const StoreItem = (props) => {
  return (
    <Grid item component={Card} sx={{ maxWidth: 345, minWidth: 345 }} mr={3} mb={3} xs>
      <CardActionArea href={"/localart/" + props.artist.split(/\s+/).join('').toLowerCase() + props.link} sx={{height:"100%"}}>
        <CardMedia
          component="img"
          height="250"
          image={props.image}
          alt={props.title}
        />
        <CardContent height="170px">
          <Typography gutterBottom variant="h3" component="div" mt={0}>{props.title}</Typography>
          <Typography gutterBottom variant="body1" component="div">{props.artist}</Typography>
          <Typography variant="body2" color="text.secondary">{props.shortDescription}</Typography>
        </CardContent>
      </CardActionArea>
    </Grid>
  )
};

export default StoreItem;