import * as React from 'react';
import StoreItem from "./StoreItem";
import Grid from "@mui/material/Grid";


const ItemContainer = (props) => {
  return (
    <Grid container alignItems="stretch">
      {props.items.map((item) => (
          <StoreItem {...item} />
      ))}
    </Grid>
  );
};

export default ItemContainer;