import * as React from 'react';
import Box from '@mui/material/Box';

const ContentPanel = (props) => {
  return (
    <Box pb="260px">
      {props.children}
    </Box>
  );
};

export default ContentPanel;