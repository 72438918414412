import * as React from 'react';
import {Route, Routes} from "react-router";
import ArtistPage from "./ArtistPage";
import AllArt from "./AllArt";
import ItemPage from "./ItemPage";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


const artists = [
  {
    name: "Jason Curtis",
    image: process.env.PUBLIC_URL + "/images/artists/jasoncurtis.jpeg",
    url: "jasoncurtisphotography.com",
    description: "Jason is a PNW-based photographer and climbing instructor. His work at the intersection of photography and mapmaking has put him" +
      " on the map and earned him a loyal following in the Seattle area.",
    longDescription: (
      <Box>
        <Typography variant="body1">
          Jason Curtis is a Pacific Northwest-based photographer focused on the outdoors. He has been a climbing instructor, member of Seattle Mountain Rescue, and licensed drone/UAS pilot. He is particularly interested in the intersection between photography and mapmaking, which is why you'll see several labeled panoramas in his portfolio.
        </Typography>
        <Typography variant="body1">
          For Jason, photography is a way to bring some of the outdoors back home. Jason is available for hire and consultation.
        </Typography>
    </Box>
    )
  },
  // {
  //   name: "Rose Hesse",
  //   image: process.env.PUBLIC_URL + "/images/artists/rosehesse.png",
  //   url: "www.rose-hesse.com/whimsy-2",
  //   description: "Rose is a Seattle-based artist who works mainly with pen & ink and watercolor. When she's not painting, Rose is a climbing" +
  //     " instructor, UX designer, and highly skilled burrito taste tester.",
  //   longDescription: (
  //     <Box>
  //       <Typography variant="body1">
  //         Coming soon!
  //       </Typography>
  //   </Box>
  //   )
  // },
  {
    name: "Jean Ruggiero",
    image: process.env.PUBLIC_URL + "/images/artists/jeanruggiero.jpeg",
    url: "www.grainandgather.com",
    description: "In my ever-dwindling free time, I love to paint with watercolors - especially mountains but other things too!",
    longDescription: (
      <Box>
        <Typography variant="body1">
          Hi, it's me again! I'm experimenting with fine art printing as a way to share some of my art. Please see below for some framed prints you can hang on your walls!
        </Typography>
    </Box>
    )
  }
];

const items = [
  {
    title: "Olympic Mountains Over Seattle Panorama",
    artist: "Jason Curtis",
    shortDescription: "Beautiful panorama featuring over 25 labeled peaks in the Olympic range and the full Seattle skyline.",
    description: "Stunning 8+ foot panorama featuring over 25 labeled peaks in the Olympic range and the full Seattle skyline in the foreground." +
      " This 10-photo panorama was taken at Sunset on May 19, 2017. It's available in a float framed triptych. Each framed panel measures " +
      "22\" x 32\". This piece is sure to make a design statement mounted above a sofa, dresser, or console table.",
    price: 1000,
    image: process.env.PUBLIC_URL + "/images/art/olympics_triptych.png",
    imageWidth: "100%",
    link: "/olympics",
    externalLink: "jasoncurtisphotography.com/store/olympic-mountains-panorama"
  },
  {
    title: "Glacier Peak at Night",
    artist: "Jason Curtis",
    shortDescription: "Limited edition framed print. Glacier Peak and the Milky Way, as seen from Itswoot ridge on the Ptarmigan Traverse.",
    description: "Limited edition custom framed Glacier Peak at Night photograph by Jason Curtis. This 13\" x 20\" photograph features Glacier Peak" +
      " and the Milky Way, as seen from Itswoot ridge on the Ptarmigan Traverse. Includes archival print, oversized designer mat, and" +
      " handcrafted 22\" x 31\" walnut frame.",
    price: 300,
    image: process.env.PUBLIC_URL + "/images/art/glacier_peak_at_night.jpeg",
    itemPageImage: process.env.PUBLIC_URL + "/images/art/glacier_framed.jpeg",
    imageWidth: "200%",
    layout: "horizontal",
    link: "/glacier",
    externalLink: "jasoncurtisphotography.com/store/glacier-peak-night"
  },
  // {
  //   title: "Mt. Gimli",
  //   artist: "Rose Hesse",
  //   shortDescription: "Coming soon!",
  //   description: "Coming soon!",
  //   price: "TBD",
  //   image: process.env.PUBLIC_URL + "/images/art/mtgimli.jpeg",
  //   imageWidth: "150%",
  //   layout: "horizontal",
  //   link: "/gimli"
  // },
  // {
  //   title: "Black Peak",
  //   artist: "Rose Hesse",
  //   shortDescription: "Coming soon!",
  //   description: "Coming soon!",
  //   price: "TBD",
  //   image: process.env.PUBLIC_URL + "/images/art/blackpeak.jpeg",
  //   imageWidth: "200%",
  //   layout: "horizontal",
  //   link: "/blackpeak"
  // },
  // {
  //   title: "Torres del Paine",
  //   artist: "Rose Hesse",
  //   shortDescription: "Coming soon!",
  //   description: "Coming soon!",
  //   price: "TBD",
  //   image: process.env.PUBLIC_URL + "/images/art/torresdelpaine.jpeg",
  //   imageWidth: "150%",
  //   layout: "horizontal",
  //   link: "/torresdelpaine"
  // },
  {
    title: "Aiguille du Midi",
    artist: "Jean Ruggiero",
    shortDescription: "Large format (22\" x 32\") archival watercolor print on 100% cotton paper.",
    description: "This massive 22\" x 32\" watercolor features Aiguille du Midi in the French Alps. Includes" +
      " archival print on 100% cotton paper, oversized designer mat, and handcrafted 43\" x 33\" white oak frame.\n",
    price: 500,
    image: process.env.PUBLIC_URL + "/images/art/aiguilledumidi.jpeg",
    imageWidth: "100%",
    layout: "horizontal",
    link: "/aiguilledumidi"
  },
  {
    title: "Plateau du Trient",
    artist: "Jean Ruggiero",
    shortDescription: "Experience the view from the first high hut of the Haute Route through the Swiss Alps. Watercolor print on 100% cotton" +
      " paper.",
    description: "This 5\" x 8\" watercolor features rock spires atop the Plateau du Trient high up in the Swiss Alps. This was painted based" +
      " off of a photograph I took while skiing the Haute Route, a famous ski traverse of the Swiss Alps high huts. Includes" +
      " matted archival print on 100% cotton paper and handcrafted 9\" x 12\" walnut frame.\n",
    price: 150,
    image: process.env.PUBLIC_URL + "/images/art/plateaudutrient.jpeg",
    imageWidth: "300%",
    layout: "horizontal",
    link: "/plateaudutrient"
  },
  {
    title: "Mt Baker from Artist Point",
    artist: "Jean Ruggiero",
    shortDescription: "Mt Baker as seen from Artist Point. Watercolor print on 100% cotton paper.",
    description: "This 7\" x 12\" watercolor featuring the majestic Mt Baker was painted entirely on scene at Artist Point. Includes" +
      " matted archival print on 100% cotton paper and handcrafted 14\" x 21\" walnut frame.\n",
    price: 300,
    image: process.env.PUBLIC_URL + "/images/art/mtbaker.jpeg",
    imageWidth: "300%",
    layout: "horizontal",
    link: "/mtbaker"
  },
  {
    title: "Jordan Pond",
    artist: "Jean Ruggiero",
    shortDescription: "Jordan Pond and The Bubbles, Acadia National Park. Watercolor print on 100% cotton paper.",
    description: "This 8\" x 11\" watercolor features the classic view of Jordan Pond in Acadia National Park. Includes" +
      " matted archival print on 100% cotton paper and handcrafted 17\" x 21\" white oak frame.\n",
    price: 250,
    image: process.env.PUBLIC_URL + "/images/art/jordanpond.jpeg",
    imageWidth: "200%",
    layout: "horizontal",
    link: "/jordanpond"
  },
  {
    title: "Tiny Lighthouse",
    artist: "Jean Ruggiero",
    shortDescription: "This tiny watercolor features one of the many lighthouses lining the rugged and beautiful Oregon coast. Printed on 100% cotton paper.",
    description: "This 3\" x 3\" watercolor features one of the many lighthouses lining the rugged and beautiful Oregon coast. Painted on the beach" +
      " in Oregon. Includes matted archival print on 100% cotton paper and handcrafted 6\" x 6\" walnut frame.\n",
    price: 75,
    image: process.env.PUBLIC_URL + "/images/art/tinylighthouse.jpeg",
    imageWidth: "200%",
    layout: "horizontal",
    link: "/tinylighthouse"
  },
  {
    title: "The Italian Dream",
    artist: "Jean Ruggiero",
    shortDescription: "This unique watercolor depicts garlic in various stages of undress. Printed on 100% cotton paper.",
    description: "This 5\" x 11\" watercolor depicts garlic in various stages of undress. Perfect for kitchens and dining rooms! Includes matted" +
      " archival print on 100% cotton paper and handcrafted 17\" x 23\" white oak frame.\n",
    price: 250,
    image: process.env.PUBLIC_URL + "/images/art/garlicundressing.jpeg",
    imageWidth: "250%",
    layout: "horizontal",
    link: "/garlicundressing"
  },

];

const LocalArt = (props) => {
  return (
    <Routes>
      <Route path="/" element={<AllArt items={items} artists={artists}/>} />
      {artists.map((artist) => (
        <Route path={"/" + artist.name.split(/\s+/).join('').toLowerCase()} element={<ArtistPage artist={artist} items={items}/>}/>
      ))}
      {items.map((item) => (
        <Route path={"/" + item.artist.split(/\s+/).join('').toLowerCase() + item.link} element={<ItemPage {...item}/>}/>
      ))}
    </Routes>
  );
};

export default LocalArt;