import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {useState} from "react";
import {useEffect} from "react";
import Box from "@mui/material/Box";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function FrameSamples(props) {

  const breakpoints = {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    };

    const getColumns = (width) => {
        if (width < breakpoints.sm) {
            return 1
        } else if (width < breakpoints.md) {
            return 2
        } else if (width < breakpoints.lg) {
            return 3
        } else if (width < breakpoints.xl) {
            return 3
        } else {
            return 3
        }
    };

    const [columns, setColumns] = useState(getColumns(window.innerWidth));
    const updateDimensions = () => {
        setColumns(getColumns(window.innerWidth))
    };

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    });

  return (
    <Box>
      <ImageList variant="masonry" sx={{ width: "90vw", height: "auto" }} cols={columns}>
        {imageList.map((filename) => (
          <ImageListItem key={base_url + filename}>
            <img
              src={`${base_url + filename}?w=248&fit=crop&auto=format`}
              srcSet={`${base_url + filename}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt="frame"
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

const base_url = process.env.PUBLIC_URL + '/images/frames/';
const imageList = ["67877170610__AA3A33C8-C384-4566-954C-463F29E02D0B.fullsizerender 2" +
" Medium.png", "AE8F5FEC-5352-4997-AB9D-60B8175717AB 2 Medium.png", "IMG_7189 2 Medium.png", "IMG_7336 Medium.png", "IMG_7337 Medium.png",  "IMG_7436 2 Medium.png", "IMG_7444 2 Medium.png", "IMG_7449 2 Medium.png", "IMG_7496 2 Medium.png", "IMG_7519_jpg 2 Medium.png", "IMG_7635 2 Medium.png", "IMG_7661 2 Medium.png", "IMG_7685 2 Medium.png", "IMG_7736 3 Medium.png", "IMG_7745 3 Medium.png", "IMG_7758 3 Medium.png", "IMG_7770 Medium.png",  "IMG_7833 2 Medium.png", "IMG_7868 2 Medium.png", "IMG_7880 2 Medium.png", "IMG_8303 2 Medium.png", "IMG_8321 2 Medium.png", "IMG_8647 2 Medium.png", "IMG_8651 2 Medium.png", "IMG_8657 2 Large.png", "IMG_8675 Large.png", "IMG_8684 Medium.png", "imagejpeg_1 2 Medium.png"];