import * as React from 'react';
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";


const ArtistCard = (props) => {
  return (
    <Grid item component={Card} sx={{ maxWidth: 345, minWidth: 345 }} mr={3} mb={3} elevation={0} xs>
      <CardActionArea href={"/localart/" + props.name.split(/\s+/).join('').toLowerCase()} disableRipple>
        <Box display="flex" alignItems="center" justifyContent="center" pt={3} pb={1}>
          <Avatar alt={props.name} src={props.image} sx={{ width: 200, height: 200 }} />
        </Box>
        <CardContent mt={0}>
          <Typography gutterBottom variant="h3" component="div" mt={0}>{props.name}</Typography>
          <Typography variant="body2" color="text.secondary">{props.description}</Typography>
        </CardContent>
      </CardActionArea>
    </Grid>
  )
};

export default ArtistCard;