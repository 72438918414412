import * as React from 'react';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import RequestDrawer from "./RequestDrawer";
import { SNSClient, PublishCommand } from "@aws-sdk/client-sns";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  radius:0.2,
  boxShadow: 24,
  p: 4,
};

const client = new SNSClient({ region: "us-west-2" });


const ItemPage = (props) => {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEmailChange = (event) => {setEmail(event.target.value);};
  const handleSubmit = () => {
    console.log("Form submitted!");
    const command = new PublishCommand(
      {
        Message: "Item: " + props.title + "\nEmail: " + email,
        Subject: "[Local Art] Request for" + props.title,
        TopicArn: 'arn:aws:sns:us-west-2:984151712730:artTopic'
      }
    );

    try {
      client.send(command);
      // process data.
    } catch (error) {
      // error handling.
    } finally {
      // finally.
    }
  };

  return (
    <Box sx={{ml: {xs: 1, sm: 3, md: 5, lg:8}, mr:{xs: 1, sm: 3, md: 20, lg:40}}} >
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/localart">Art</Link>
        <Link underline="hover" color="inherit" href={"/localart/" + props.artist.split(/\s+/).join('').toLowerCase()}>{props.artist}</Link>
        <Typography color="text.primary">{props.title}</Typography>
      </Breadcrumbs>


      <Typography variant="h1" gutterBottom mt={1}>{props.title}</Typography>
      <Box display={{xs: 'block', md: props.layout === "horizontal" ? "flex" : "block"}}>
        <Box sx={{width: {xs: '100%', md: props.layout === "horizontal" ? props.imageWidth : "100%"} }}>
          <img
            src={props.itemPageImage ? props.itemPageImage : props.image}
            alt={props.title}
            loading="lazy"
            style={{maxWidth: "100%", height: "auto"}}
          />
        </Box>

        <Box ml={{xs: 1, md: props.layout === "horizontal" ? 5 : null}} mr={1}>

          <Typography variant="h3" mt={{xs: 2, md:props.layout === "horizontal" ? 5 : null}}>${props.price}</Typography>

          <Typography variant="body1">{props.description}</Typography>

          {props.buyButton ? (
            <Button color="secondary" variant="contained" href={props.buyButton} target="_blank">Buy Now</Button>
          ) : (

            <Box>
              <Typography variant="body1">
                I'm currently framing these to order - please use the button below to submit a request and I'll be in touch to discuss your order!
              </Typography>

              <Button color="secondary" variant="contained" href={"mailto:jean@grainandgather.com?subject=Request for " + props.title +
                "&body=Hi there, I'd like to purchase a framed version of " + props.title + "!"}>I want one!</Button>
              {/*<Button color="secondary" variant="contained" onClick={handleOpen}>I want one!</Button>*/}
            </Box>
          )}

          {props.externalLink ? (
            <Typography variant="body1" pt={2}>
              Want it unframed? You can find that&nbsp;
              <Link href={"https://" + props.externalLink} color="secondary" underline="none" fontWeight={400}>
                here
              </Link>.
            </Typography>
            ) : null}
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{display: { xs: 'none', md: 'flex' } }}
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h3" mt={0} component="h2">
            Enter your email address and I'll be in touch to arrange your order!
          </Typography>
          <Box>
            <TextField
              id="outlined-name"
              label="Email"
              value={email}
              color="secondary"
              onChange={handleEmailChange}
              sx={{width:"85%"}}
              required
            />
          </Box>

          <Button color="secondary" variant="contained" onClick={handleSubmit}>REQUEST</Button>
        </Box>
      </Modal>

      <RequestDrawer
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        sx={{display: { xs: 'flex', md: 'none' } }}
      >
        <Box p={2}>
          <Typography id="modal-modal-title" variant="h3" mt={0} component="h2">
            Enter your email address and I'll be in touch to arrange your order!
          </Typography>
          <Box pb={2}>
            <TextField
              id="outlined-name"
              label="Email"
              value={email}
              color="secondary"
              onChange={handleEmailChange}
              sx={{width:"100%", margin:"auto"}}
              required
            />
          </Box>
          <Button color="secondary" variant="contained" onClick={handleSubmit}>REQUEST</Button>
        </Box>


      </RequestDrawer>

    </Box>
  )
};

export default ItemPage;
