import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import ArtistList from "./ArtistList";
import ItemContainer from "./ItemContainer";

const AllArt = (props) => {
  return (
    <Box sx={{ml: {xs: 1, sm: 3, md: 5, lg:8}, mr:{xs: 1, sm: 3, md: 5, lg:8}} }>

      <Typography variant="h2" mt={4}>Shop Local Art</Typography>

      <Typography variant="body1">
        Shop art made with love by local artists right here in the Pacific Northwest.
      </Typography>

      <Typography variant="h3">Shop by Artist</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <ArtistList artists={props.artists}/>
      </Box>


      <Typography variant="h3">All Art</Typography>
      <ItemContainer items={props.items}/>
    </Box>
  );
};

export default AllArt;