import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Services = (props) => {
  return (
    <Box sx={{ml: {xs: 1, sm: 3, md: 5, lg:8}, mr:{xs: 1, sm: 3, md: 6, lg:10}} }>
      <Box mb="65px">
        <Typography variant="h2" mt={4}>Interior Design Services</Typography>

        <Typography variant="body1">
          Don’t see what you’re looking for? <Link href="mailto:design@grainandgather.com" sx={{color: "#6e554e", fontWeight:400}}>Send me a note</Link> and ask!
        </Typography>
      </Box>

      <Typography variant="h3">Space Planning</Typography>

      <Typography variant="body1">
        If you’re looking to refresh the layout of your space, I’m here to help! I’ll help you plan out where to place your existing furniture and acquire additional pieces for your space.
      </Typography>

      <Typography variant="h3">Color Consultation</Typography>

      <Typography variant="body1">
        Are you ready for a change of scenery but not sure where to start? I can help by recommending colors for paint, upholstery, decor, and other design elements. I’ll work with you to select a general color family and narrow it down to a few options. I’ll then acquire color samples/swatches and walk you through making a final decision.
      </Typography>

      <Typography variant="h3">Blank Slate Design</Typography>


      <Typography variant="body1">
        If you’re looking to design a newly constructed space, or completely redesign an existing space, this option is for you! Designs will include options for flooring, trim, lighting, paint colors, and matching furniture and decor items.
      </Typography>

      <Typography variant="h3">Custom Framing</Typography>

      <Typography variant="body1">
        Let’s face it, buying frames online gets expensive…fast! If you’re looking for frames that match your design style - whether custom or standard sizes - I can build them to order for you. This service is offered to local customers only due to the difficulty of shipping frames. <Link href="/framing" sx={{color: "#6e554e", fontWeight:400}}>Learn more.</Link>
      </Typography>


    </Box>
  );
};

export default Services;