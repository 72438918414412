import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {useState} from "react";
import {useEffect} from "react";


export default function MasonryImageList(props) {

  const breakpoints = {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    };

    const getColumns = (width) => {
        if (width < breakpoints.sm) {
            return 1
        } else if (width < breakpoints.md) {
            return 3
        } else if (width < breakpoints.lg) {
            return 3
        } else if (width < breakpoints.xl) {
            return 3
        } else {
            return 3
        }
    };

    const [columns, setColumns] = useState(getColumns(window.innerWidth));
    const updateDimensions = () => {
        setColumns(getColumns(window.innerWidth))
    };

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    });

  return (
    <ImageList variant="masonry" sx={{ width: "auto", height: "auto" }} cols={columns}>
      {props.items.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}