import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import PriceList from "./PriceList";
import FrameSamples from "./FrameSamples";
import FrameCalculator from "./FrameCalculator";

const Framing = (props) => {
  return (
    <Box sx={{ml: {xs: 1, sm: 3, md: 5, lg:8}, mr:{xs: 1, sm: 3, md: 20, lg:40}} }>
      <Box mb="65px">

        <Typography variant="h2" mt={4}>Custom Framing</Typography>

        <Typography variant="body1">
          My goal is to offer affordable frames by charging just enough to cover the cost of raw materials and partially offset the cost of tools and of running a business. These prices are lower than similar quality frames you can buy online and far cheaper than custom framing from a frame shop. Plus, you’re helping to reduce emissions and keep packaging out of the landfill by buying local!
        </Typography>

        <Typography variant="body1">
          Please <Link href="mailto:design@grainandgather.com" sx={{color: "#6e554e", fontWeight:400}}>email me</Link> to place an order or check out my selection of <Link href="/localart" sx={{color: "#6e554e", fontWeight:400}}>framed local art</Link>!
        </Typography>
      </Box>

      <div>
        <img src={process.env.PUBLIC_URL + "/images/frames/IMG_7833 2.png"} width="100%" alt="Framed Jason Curtis Photography panorama triptych"/>
      </div>

      <Typography variant="h2">Price List</Typography>

      <Typography variant="body1">
        This list of standard sizes is to help you estimate the cost of a frame; size options are not limited to those on this list. Prices include matting, hardware, glass (acrylic for large sizes), and a wood and finish of your choice.
      </Typography>

      <Box display={{xs: "block", md: "flex"}}>
        <Box mr={6}>
          <Typography variant="h3">Standard Sizes</Typography>
          <PriceList/>
        </Box>
        <Box>
          <Typography variant="h3">Custom Sizes</Typography>
          <FrameCalculator/>
        </Box>
      </Box>



      <Typography variant="body1">
        Prices do not include tax.
      </Typography>

      <Typography variant="h2">Handcrafted Quality</Typography>

      <Typography variant="body1">
        Most frame shops and online sellers join their frames with poor quality metal fasteners, which lead to the corners separating over time. Nobody wants that! I build all of my frames with furniture grade joinery and high quality materials. Frames are sanded and finished after they're built for a polished and professional look.
      </Typography>


      <Typography variant="h2">What’s Included?</Typography>

      <Typography variant="h3" mt={0}>Hardware</Typography>
      <Typography variant="body1">
        All frames come with hanging hardware including a wire and wall hook or drywall anchor.
      </Typography>

      <Typography variant="h3">Glass</Typography>
      <Typography variant="body1">
        All frames less than 35” x 25” come with your choice of glass or high quality acrylic. Glass becomes too heavy at larger sizes so large frames are offered with acrylic only.
      </Typography>

      <Typography variant="h3">Matting</Typography>

      <Typography variant="body1">
        All prices include matting. If your work is already matted, you’ll save $5 - $15 per frame. The dimensions of the frame refer to the size of the matboard that will fit in the frame. The outside dimensions of the frame will be 1” wider and 1” taller than the listed size.
      </Typography>
      <Typography variant="body1">
        If your art is already matted, measure the size of the matboard to determine what size frame you need. For unmatted art, I’ll help you decide on a mat/frame size. As a general rule, larger mats look more designer - they make your art look bigger and draw more attention.
      </Typography>
      <Typography variant="body1">
        I'll recommend a mat color based on the color of your artwork and paper (if applicable). The color options that look best in almost all cases are: warm white, pure white, or off-white. I’d highly encourage sticking with one of the default colors for a classic look that will stand the test of time but I can do other colors too.
      </Typography>

      <Typography variant="h3">Wood & Finish</Typography>
      <Typography variant="body1">
        White oak and walnut are the two woods I recommend in most cases. I can recommend a wood based on your artwork or you can choose based on personal preference or the decor of your home. I can make frames out of any type of hardwood available including walnut, white oak, red oak, beech, and cherry.
      </Typography>


      <Typography variant="h2">Side Notes</Typography>
      <Typography variant="body1">
        The prices included here are for picture frames but I can also make float frames for canvas, panel, or aluminum pieces.
      </Typography>
      <Typography variant="body1">
        Art should be hung with the center at eye level (about 60” — 63”).
      </Typography>

      <Typography variant="h2">Custom Framing Portfolio</Typography>
      <Typography variant="body1">
        Check out my <Link href="https://www.instagram.com/grain_and_gather/" sx={{color: "#6e554e", fontWeight:400}}>Instagram</Link> for the latest!
      </Typography>



      <FrameSamples/>


    </Box>
  );
};

export default Framing;