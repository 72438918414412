import * as React from 'react';
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import ItemContainer from "./ItemContainer";
import Avatar from "@mui/material/Avatar";

const ArtistPage = (props) => {
  return (
    <Box sx={{ml: {xs: 1, sm: 3, md: 5, lg:8}, mr:{xs: 1, sm: 3, md: 5, lg:8}}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/localart">
          Art
        </Link>
        <Typography color="text.primary">{props.artist.name}</Typography>
      </Breadcrumbs>


      <Box display={{xs: "block", md:"flex"}}>
        <Box display="flex" alignItems="center" justifyContent="center" pt={0} pb={1} pr={{xs: 0, md: 5}}>
          <Avatar alt={props.artist.name} src={props.artist.image} sx={{ width: 250, height: 250 }} />
        </Box>

        <Box>
          <Typography variant="h1" gutterBottom mt={1}>{props.artist.name}</Typography>
          <Link href={"https://" + props.artist.url} underline="none" color="secondary" variant="body1" sx={{ fontWeight:500 }} mb={1}>
            {props.artist.url}
          </Link>
          {props.artist.longDescription}
        </Box>
      </Box>



      <Box mt={5}>
        <Typography variant="h2" gutterBottom mt={1}>{props.artist.name.split(" ")[0]}'s Art</Typography>
        <ItemContainer items={props.items.filter((item) => item.artist === props.artist.name)}/>
      </Box>


    </Box>


  )
};

export default ArtistPage;