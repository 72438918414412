import * as React from 'react';
import Box from '@mui/material/Box';

const PriceList = (props) => {
  return (
    <Box mt={3} mb={1}>
      <table>
        <thead>
          <tr>
            <th>Art Size</th>	<th>Frame Size</th>	<th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>4 x 6</td> <td>8 x 10</td> <td>$73</td>
          </tr>
          <tr>
            <td>5 x 7</td> <td>9 x 11</td> <td>$76</td>
          </tr>
         <tr>
            <td>6 x 8</td> <td>10 x 12</td> <td>$78</td>
          </tr>
          <tr>
            <td>9 x 12</td> <td>16 x 19</td> <td>$99</td>
          </tr>
          <tr>
            <td>12 x 18</td> <td>20 x 26</td> <td>$130</td>
          </tr>
          <tr>
            <td>20 x 20</td> <td>30 x 30</td> <td>$187</td>
          </tr>
          <tr>
            <td>16 x 24</td> <td>26 x 34</td> <td>$186</td>
          </tr>
          <tr>
            <td>20 x 30</td> <td>30 x 40</td> <td>$250</td>
          </tr>
        </tbody>
      </table>
    </Box>
  )
};

export default PriceList;