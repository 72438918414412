import * as React from 'react';
import ArtistCard from "./ArtistCard";
import Grid from "@mui/material/Grid";

const ArtistList = (props) => {
  return (
    <Grid container alignItems="stretch" mt={2}>
      {props.artists.map((artist) => (
          <ArtistCard name={artist.name} image={artist.image} description={artist.description} />
      ))}
    </Grid>
  )

};

export default ArtistList;