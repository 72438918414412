import * as React from 'react';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

const standard_border = (dim) => {
  // Calculates the mat border for a matted piece based on the critical dimension dim.
  if (dim <= 6) {
    return 2
  } else if (dim <= 7) {
    return 2.5
  } else if (dim <= 11) {
    return 3.5
  } else if (dim <= 15) {
    return 4
  } else if (dim <= 26) {
    return 5
  } else {
    return 6
  }
};

const matBorder = (art_width, art_height) => {
  const short_side = Math.min(art_width, art_height);
  const long_side = Math.max(art_width, art_height);

  // Frame panoramic pieces with a border based on the shortest side to preserve aspect ratio
  const dim = long_side < 2 ? long_side : short_side;
  return standard_border(dim);
};

const boardCost = (standard_sheet_cost, large_sheet_cost, width, height) => {
  const short_side = Math.min(width, height);
  const long_side = Math.max(width, height);

  if (short_side <= 32 && long_side <= 40) {
    // This frame can be made with standard size matboard
    if (short_side <= 16 && long_side <= 20) {
      return standard_sheet_cost * 0.25;
    } else if (short_side <= 20 && long_side <= 32) {
      return standard_sheet_cost *  0.5;
    } else {
      return standard_sheet_cost * 1;
    }
  } else {
    // Charge for a full large sheet
    return large_sheet_cost;
  }
};

const matboardCost = (width, height) => {
  return boardCost(15.17, 75, width, height);
};

const foamcoreCost = (width, height) => {
  return boardCost(5, 35, width, height);
};

const area = (width, height) => {
  // Area in sq ft
  return (width * height) / 144;
};

const glazingCost = (width, height) => {
  const acrylic_cost = 4.45;
  const glass_cost = 2.63;

  if (width + height > 60) {
    // Large frames must have acrylic
    return acrylic_cost * area(width, height);
  } else {
    return glass_cost * area(width, height);
  }
};

const woodCost = (width, height) => {
  const cost_per_linear_foot = 2.88;
  return cost_per_linear_foot * (width * 2 + height * 2 + 4) / 12;
};

const materialCost = (width, height) => {
  const fixed_cost = 3.53;
  return fixed_cost + matboardCost(width, height) + foamcoreCost(width, height) + glazingCost(width, height) + woodCost(width, height);
};

const price = (width, height) => {
  const price_multiplier = 0.01 * (width + height) + 1.5;
  return 40 + materialCost(width, height) * price_multiplier;
};

const FrameCalculator = (props) => {
  const [length, setLength] = React.useState(null);
  const [width, setWidth] = React.useState(null);
  const [matted, setMatted] = React.useState(false);
  const [type, setType] = React.useState("photo");

  const handleLengthChange = (event) => {
    setLength(event.target.value);
  };
  const handleWidthChange = (event) => {
    setWidth(event.target.value);
  };
  const handleMattedChange = (event) => {
    setMatted(event.target.checked);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const matBorderSize = matBorder(parseFloat(length), parseFloat(width));
  const frameLength = matted || type === "float" ? parseFloat(length) : parseFloat(length) + 2 * matBorderSize;
  const frameWidth = matted || type === "float" ? parseFloat(width) : parseFloat(width) + 2 * matBorderSize;

  let cost = 0;

  if (type === "photo") {
    cost = price(frameLength, frameWidth).toFixed(0);
  } else {
    if (frameLength * frameWidth <= 200) {
      cost = (
        ((19.5 + 6.3 * (frameLength + frameWidth + 4) / 6.0) * 2.0)
      ).toFixed(0);
    } else {
      cost = (
        ((19.5 + 6.3 * (frameLength + frameWidth + 4) / 6.0) * 2.3)
      ).toFixed(0);
    }
  }

  return (
    <Box mt={3}>


      <Typography variant="body1" mb={0.5}>{matted ? "Mat Size" : "Art Size"}</Typography>

      <Box display={{xs:"block", md:"flex"}} alignItems="bottom">
        <Box>
          <TextField
            label="Length"
            InputLabelProps={{
              shrink: true,
            }}
            value={length}
            onChange={handleLengthChange}
            style = {{width: 80, marginRight: 10}}
            error={length !== null && isNaN(parseInt(length))}
          />
          <TextField
            label="Width"
            InputLabelProps={{
              shrink: true,
            }}
            value={width}
            onChange={handleWidthChange}
            style = {{width: 80, marginRight: 10}}
            error={width !== null && isNaN(parseInt(width))}
          />
        </Box>

        <Box mt="auto">
          <Box mb={-2}>
            <FormControl>
              <RadioGroup row value={type} onChange={handleTypeChange}>
                <FormControlLabel value="photo" control={<Radio size="large"/>} label="Photo"/>
                <FormControlLabel value="float" control={<Radio size="large"/>} label="Float"/>
              </RadioGroup>
            </FormControl>
          </Box>

          <Box>
            <FormControlLabel
              label="My art is already matted."
              display="block"
              control={
                <Checkbox
                  checked={matted}
                  onChange={handleMattedChange}
                  size="large"
                  disabled={type === "float"}
                />
              } />
          </Box>
        </Box>
      </Box>

      <Typography variant="body1" mb={0.5}>Recommended Frame Size</Typography>

      <Box mt={2}>
        <table>
          <tr>
            <td className="custom-price">{isNaN(frameLength) ? '' : frameLength} {isNaN(frameWidth) ? '' : 'x ' + frameWidth}</td>
            <td className="custom-price">{isNaN(cost) ? '' : '$' + cost}</td>
          </tr>
          <tr>
            <td className="custom-price-label">Size</td>
            <td className="custom-price-label">Cost</td>
          </tr>
        </table>
      </Box>
    </Box>
  )
};

export default FrameCalculator;

