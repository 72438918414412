import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import MasonryImageList from "./MasonryImageList";


const calmingBedroomItems = [
  {
    img: process.env.PUBLIC_URL + "/images/bedroom/IMG_7200.png",
    title: 'Dresser',
    rows: 4,
    cols: 2
  },
  {
    img: process.env.PUBLIC_URL + "/images/bedroom/IMG_7207.png",
    title: 'Floor mirror',
    rows: 4,
    cols: 2,
  },
  {
    img: process.env.PUBLIC_URL + "/images/bedroom/IMG_7204.png",
    title: 'Decorative vase and candles',
    rows: 4,
    cols: 2
  },
  {
    img: process.env.PUBLIC_URL + "/images/bedroom/IMG_6577.png",
    title: 'Whole bedroom view',
    rows: 2,
    cols: 6,
  },
];

const coffeeBarItems = [
  {
    img: process.env.PUBLIC_URL + "/images/coffee_bar/IMG_7129.png",
    title: 'Coffee',
    rows: 6,
    cols: 4
  },
  {
    img: process.env.PUBLIC_URL + "/images/coffee_bar/IMG_7127.png",
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },

  {
    img: process.env.PUBLIC_URL + "/images/coffee_bar/IMG_7131.png",
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
];

const kitchenItems = [
  {
    img: process.env.PUBLIC_URL + "/images/kitchen/IMG_7119.png",
    title: 'Cabinets',
    rows: 3,
    cols: 6
  },
  {
    img: process.env.PUBLIC_URL + "/images/kitchen/IMG_7117.png",
    title: 'Cabinets',
    rows: 2,
    cols: 4,
  },
  {
    img: process.env.PUBLIC_URL + "/images/kitchen/IMG_7120.png",
    title: 'Cabinets',
    rows: 2,
    cols: 2,
  },
];

const Portfolio = (props) => {
  return (
    <Box sx={{mx: {xs: 1, sm: 3, md: 5, lg:8} }}>
      <Typography variant="h2" mt={4}>Portfolio</Typography>


      <Typography variant="h3">Calming Bedroom</Typography>
      <MasonryImageList items={calmingBedroomItems}/>

      <Typography variant="h3">Sun Soaked Coffee Bar</Typography>
      <MasonryImageList items={coffeeBarItems} />

      <Typography variant="h3">Contemporary Cabinets</Typography>
      <MasonryImageList items={kitchenItems} />

    </Box>
  );
};

export default Portfolio;