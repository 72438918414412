import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

export default function RequestDrawer(props) {
  return (
    <div>
      <SwipeableDrawer
        anchor="bottom"
        open={props.open}
        onClose={props.handleClose}
        onOpen={props.handleOpen}
        {...props}
      >
        {props.children}
      </SwipeableDrawer>
    </div>
  );
}